import * as React from "react"
import agreementJson from "../../pages/agreement.json"
import { marked } from 'marked';
import DefaultHead from "../components/layout/head";

// markup
const AgreementPage = ({pageContext}) => {
    const {lang} = pageContext
    const {paragraphs=[]} = agreementJson[lang]
    marked.setOptions({
        breaks: true,
    })
    return (
        <div className={"page-agreement"}>
            <div className="text">
                {paragraphs.map((p,k)=> {
                    const classNames = ['text__part'];
                    if(/^## /.test(p)) classNames.push('text__part--heading')
                    return <div key={k} className={classNames.join(' ')} dangerouslySetInnerHTML={{__html: marked.parse(p)}}/>
                })}
            </div>
        </div>
    )
}

export default AgreementPage

export const Head = DefaultHead
